(function ($) {
  Drupal.behaviors.contentBlock5V1 = {
    attach: function (context, settings) {
      var self = this;
      var $modules = $('.js-content-block5-buttons', context);
      var $wrappers = $('.js-content-block5', context);
      var $matchHeightElems = $wrappers.find('[data-mh]');
      var scriptNode = document.createElement('script');

      $matchHeightElems.length > 0 && self.matchHeight($matchHeightElems);

      if (!settings.bambuser_source) {
        return false;
      }
      if (!window.initBambuserLiveShopping) {
        window.initBambuserLiveShopping = function (item) {
          window.initBambuserLiveShopping.queue.push(item);
        };
        window.initBambuserLiveShopping.queue = [];
        scriptNode['src'] = settings.bambuser_source;
        document.body.appendChild(scriptNode);
      }
      $modules.each(function () {
        self.init($(this));
      });
    },

    init: function ($module) {
      var self = this;
      var streamId = $module.data('stream-id').trim();
      var $elements = $('.js-bambuser-button', $module);

      $elements.each(function () {
        self.setup($(this), streamId);
      });
    },

    setup: function ($elem, streamId) {
      if ((!$elem && $elem.length) || $elem.hasClass('bambuser-initialized')) {
        return false;
      }
      var randomId = 'bambuser-trigger-' + streamId;

      if (!$elem.is('a')) {
        $elem.addClass('clickable');
      }
      $elem.attr({
        id: randomId,
        role: 'button',
        tabindex: 0,
        href: 'javascript:void(0);'
      });
      $elem.addClass('bambuser-initialized');
      window.initBambuserLiveShopping({
        showId: streamId,
        node: document.getElementById(randomId),
        type: 'overlay'
      });
    },

    matchHeight: function ($matchHeightElems) {
      var $images = $matchHeightElems.find('img.lazyload');

      $images.once('mh-trigger').on('load', function () {
        var $parent = $(this).closest('[data-mh]');
        var $siblings = $parent.siblings('[data-mh]');

        $siblings.add($parent).matchHeight();
      });
    }
  };
})(jQuery);
